<template>
  <div>
    <b-table
      v-if="dataList.length > 0"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <div class="text-primary">
          <b-link
            :href="baseURL + data.item.filename"
            target="_blank"
          >
            {{ data.item.title }}
          </b-link>
          <div class="text-muted font-small-2">
            {{ moment(data.item.created).format('llll') }} tarihinde
            {{ data.item.username }} tarafından yüklendi.
          </div>
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="flat-danger"
          class="btn-icon"
          size="sm"
          pill
          @click="removeData(data.item.id)"
        >
          <FeatherIcon icon="TrashIcon" />
        </b-button>
      </template>
    </b-table>
    <b-card-body class="bg-light">
      <b-alert
        :show="uploading"
        variant="primary"
      >
        <div class="alert-body text-center">
          <b-spinner />
          <div class="mt-1">
            Dosya Yükleniyor...
          </div>
        </div>
      </b-alert>
      <b-row
        v-if="!uploading"
        class="align-items-center"
      >
        <b-col>
          <b-form-group
            label="Dosya Adı"
            label-for="image-title"
          >
            <b-form-input
              v-model="dataItem.title"
              placeholder="Dosya Adı"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Dosya Yükle"
            label-for="image-upload"
          >
            <b-form-file
              id="file-upload"
              v-model="dataItem.upload_file"
              placeholder="Bir dosya seçin..."
              drop-placeholder="Dosyayı buraya bırakın..."
              browse-text="Dosya Seçin"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            variant="success"
            :disabled="!dataItem.title || !dataItem.upload_file"
            @click="uploadFile"
          >
            <FeatherIcon icon="UploadIcon" /> Yükle
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BLink,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'ServiceMedia',
  components: {
    BTable,
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BLink,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/services/`,
      fields: [
        {
          key: 'title',
          label: 'DOSYA',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceFiles/dataList']
    },
    dataItem() {
      return this.$store.getters['serviceFiles/dataItem']
    },
    saveData() {
      return this.$store.getters['serviceFiles/dataSaveStatus']
    },
    uploading() {
      return this.$store.getters['serviceFiles/uploading']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
      this.$store.commit('serviceFiles/RESET_DATA_ITEM')
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    uploadFile() {
      this.dataItem.id_services = this.$route.params.id
      this.$store.dispatch('serviceFiles/uploadData', this.dataItem)
    },
    getDataList() {
      this.$store.dispatch('serviceFiles/getDataList', this.$route.params.id)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceFiles/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
