<template>
  <b-card
    class="text-center"
    :title="signTitle"
  >
    <v-perfect-signature
      ref="signaturePad"
      class="bg-light"
      :stroke-options="strokeOptions"
      background-color="rgba(255,255,255,1)"
    />
    <div class="mt-1 d-flex justify-content-around">
      <b-button
        variant="success"
        @click="toDataURL"
      >
        <FeatherIcon icon="SaveIcon" /> Kaydet
      </b-button>
      <b-button
        variant="warning"
        @click="clearSign"
      >
        <FeatherIcon icon="XIcon" />
        Temizle
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { VPerfectSignature } from 'v-perfect-signature'
import { BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'Signature',
  components: {
    VPerfectSignature,
    BCard,
    BButton,
  },
  props: {
    signTitle: {
      type: String,
      default: '',
    },
    signType: {
      type: String,
      required: true,
    },
    saveAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      strokeOptions: {
        size: 12,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
    }
  },
  methods: {
    toDataURL() {
      const dataURL = this.$refs.signaturePad.toDataURL('image/jpeg', 1.0)
      this.saveAction(dataURL)
    },
    clearSign() {
      this.$refs.signaturePad.clear()
    },
  },
}
</script>
