<template>
  <div>
    <b-card title="Makina Bilgileri">
      <machine />
    </b-card>
    <b-card title="Servis Özeti">
      <service-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Yedek Parçalar</b-card-title>
      </b-card-header>
      <spare-parts />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Dosya Eki</b-card-title>
      </b-card-header>
      <service-media />
    </b-card>
    <b-card title="Servis Detayı">
      <service-detail />
      <signature-view :set-sign-data="setSignData" />
      <div class="mt-1 d-flex justify-content-between">
        <div>
          <div class="d-inline">
            <b-button
              variant="warning"
              class="mr-1"
              :to="'/app/services/edit/' + dataItem.id"
            >
              <FeatherIcon icon="EditIcon" />
              Güncelle
            </b-button>
          </div>
          <div class="d-inline">
            <b-button
              :href="downloadUrl"
              target="_blank"
              variant="success"
            >
              <FeatherIcon icon="PrinterIcon" />
              Yazdır
            </b-button>
          </div>
          <div class="d-inline">
            <b-button
              variant="danger"
              class="ml-1"
              @click="toggleMailForm"
            >
              <FeatherIcon icon="MailIcon" />
              E-Posta
            </b-button>
          </div>
        </div>
        <div>
          <div class="d-inline">
            <b-dropdown variant="outline-success">
              <template #button-content>
                <feather-icon
                  icon="PenToolIcon"
                />
                Dijital İmza
              </template>
              <b-dropdown-item @click="setSignData(1)">
                Servis İmza
              </b-dropdown-item>
              <b-dropdown-item @click="setSignData(2)">
                Müşteri İmza
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-card>
    <mail-form
      v-if="emailForm"
    />
    <mail-logs />
    <signature
      v-if="signType"
      :save-action="saveSign"
      :sign-type="signType"
      :sign-title="signTitle"
    />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Machine from '@/views/Admin/Services/View/Machine.vue'
import ServiceSummary from '@/views/Admin/Services/View/ServiceSummary.vue'
import SpareParts from '@/views/Admin/Services/View/Spareparts.vue'
import ServiceDetail from '@/views/Admin/Services/View/ServiceDetail.vue'
import ServiceMedia from '@/views/Admin/Services/View/ServiceMedia.vue'
import SignatureView from '@/views/Admin/Services/View/SignatureView.vue'
import Signature from '@/views/Admin/Services/View/Signature.vue'
import MailLogs from '@/views/Admin/Services/View/MailLogs.vue'
import MailForm from '@/views/Admin/Services/View/MailForm.vue'

export default {
  name: 'ServiceView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BDropdown,
    BDropdownItem,
    Machine,
    ServiceSummary,
    SpareParts,
    ServiceDetail,
    ServiceMedia,
    SignatureView,
    Signature,
    MailLogs,
    MailForm,
  },
  data() {
    return {
      emailForm: false,
      signType: null,
      signTitle: null,
      downloadUrl: `${this.$store.state.app.baseURL}/Exports/services/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    machine() {
      return this.$store.getters['customerMachines/dataItem']
    },
  },
  methods: {
    toggleMailForm() {
      this.emailForm = !this.emailForm
    },
    saveSign(data) {
      if (data) {
        this.$store.dispatch('services/saveSign', {
          id: this.dataItem.id,
          type: this.signType,
          upload_image: data,
        })
          .then(res => {
            if (res) {
              this.signType = null
              this.signTitle = null
            }
          })
      }
    },
    setSignData(type) {
      if (type === 1) {
        this.signType = 'service'
        this.signTitle = 'Servis Sorumlusu İmzası'
      }
      if (type === 2) {
        this.signType = 'customer'
        this.signTitle = 'Müşteri İmzası'
      }
    },
  },
}
</script>
