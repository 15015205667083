<template>
  <b-row class="mb-2">
    <b-col class="text-center mt-2">
      <div class="font-weight-bold font-medium-2 mb-1 text-primary">
        Servis Sorumlusu İmzası
      </div>
      <div
        v-if="dataItem.sign_service"
        class="border-1 border-top border-right border-bottom border-left rounded overflow-hidden"
      >
        <b-img
          :src="imageUrl + dataItem.sign_service"
          fluid
        />
      </div>
      <div v-else>
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body py-5">
            <FeatherIcon
              icon="InfoIcon"
              size="24"
              class="mb-1"
            />
            <p>Servis Sorumlusu İmzası bulunamadı.</p>
            <b-button
              class="mt-1"
              variant="warning"
              @click="setSignData(1)"
            >
              <feather-icon
                icon="PenToolIcon"
              />
              İmza Oluştur
            </b-button>
          </div>
        </b-alert>
      </div>
    </b-col>
    <b-col class="text-center mt-2">
      <div class="font-weight-bold font-medium-2 mb-1 text-primary">
        Müşteri İmzası
      </div>
      <div
        v-if="dataItem.sign_customer"
        class="border-1 border-top border-right border-bottom border-left rounded overflow-hidden"
      >
        <b-img
          :src="imageUrl + dataItem.sign_customer"
          fluid
        />
      </div>
      <div v-else>
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body py-5">
            <FeatherIcon
              icon="InfoIcon"
              size="24"
              class="mb-1"
            />
            <p>Müşteri İmzası bulunamadı.</p>
            <b-button
              class="mt-1"
              variant="warning"
              @click="setSignData(2)"
            >
              <feather-icon
                icon="PenToolIcon"
              />
              İmza Oluştur
            </b-button>
          </div>
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BImg, BAlert, BButton,
} from 'bootstrap-vue'

export default {
  name: 'SignatureView',
  components: {
    BRow,
    BCol,
    BImg,
    BAlert,
    BButton,
  },
  props: {
    setSignData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: `${this.$store.state.app.baseURL}/media/services/sign/`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
